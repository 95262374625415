import { initializeApp } from 'firebase/app'
import { getAuth, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { setAuthorization, unsetAuthorization } from './httpService'

const firebaseConfig = {
  apiKey: 'AIzaSyClb9HSpmBRNv4OQOCmLKxpcjH-qTo6HuA',
  authDomain: 'deadheads-d053e.firebaseapp.com',
  databaseURL: 'https://deadheads-d053e-default-rtdb.firebaseio.com',
  projectId: 'deadheads-d053e',
  storageBucket: 'deadheads-d053e.appspot.com',
  messagingSenderId: '965884108484',
  appId: '1:965884108484:web:633f319f505e873a3bb391'
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export async function getCurrentUser() {
  await setPersistence(auth, browserLocalPersistence)

  if (auth.currentUser) {
    setAuthorization(auth.currentUser.accessToken)
  }

  return auth.currentUser
}

export async function login(email, password) {
  await setPersistence(auth, browserLocalPersistence)
  const { user } = await signInWithEmailAndPassword(auth, email, password)

  setAuthorization(user.accessToken)

  return {
    user
  }
}

export async function logout() {
  await signOut(auth)
  unsetAuthorization()
}
