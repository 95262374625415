import store from '../store'
import { randomStr } from '../utils/helpers'

export function showSnackbar(content, color = 'success') {
  let timeout = null
  let snackbar = {
    data: {
      id: randomStr(),
      content,
      show: false,
      color,
    },
    close() {
      clearTimeout(timeout)
      hide(snackbar)
    }
  }

  store.commit('PUSH_SNACKBAR', snackbar)

  setTimeout(() => {
    snackbar.data.show = true
  }, 100)

  timeout = setTimeout(() => {
    hide(snackbar)
  }, 5000)
}

function hide(snackbar) {
  snackbar.data.show = false

  setTimeout(() => {
    store.commit('POP_SNACKBAR', snackbar)
  }, 300)
}

export default {
  install(Vue) {
    Vue.prototype.$showSnackbar = showSnackbar
  }
}
