<template>
  <v-modal :active="active" @close="$emit('close')">
    <div slot="header">
      <h5>{{ header }}</h5>
    </div>
    <div slot="body">
      {{ body }}
      <slot />
    </div>
    <div slot="footer">
      <div class="text-right">
        <button type="button" class="btn btn-light" @click="$emit('close')" v-if="!submitting">
          Cancel
        </button>
        <button type="button" :class="submitButtonClass" @click="$emit('submit')" :disabled="submitting">
          {{ submitting ? submittingText : submitButtonText }}
        </button>
      </div>
    </div>
  </v-modal>
</template>

<script>
import VModal from './Modal.vue'

export default {
  name: 'ConfirmationModal',
  components: {
    VModal,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: false,
    },
    submittingText: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    submitButtonColor: {
      type: String,
      required: true,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    submitButtonClass() {
      return `btn btn-${this.submitButtonColor} ml-1`
    }
  }
}
</script>
