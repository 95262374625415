<template>
  <div id="dashboard">
    <v-export-to-csv
      class="float-right"
      @beginExport="modals.loading.active = true"
      @endExport="modals.loading.active = false" />

    <h1 class="mb-4">Orders ({{ total }})</h1>

    <div class="row">
      <div class="col-md-6">
        <v-input type="search" name="search" label="Search" hint="name, or email" v-model="filters.search" @enterPressed="onFiltered" :disabled="networkError">
          <button class="btn btn-primary" slot="append" @click="onFiltered" :disabled="loading || networkError">
            <i class="fa fa-search"></i>
          </button>
        </v-input>
      </div>
      <div class="col-md-6">
        <v-select
          name="status"
          id="filter-status"
          label="Status"
          :items="statusOptions"
          v-model="filters.status"
          @input="onFiltered"
          :disabled="loading || networkError"
        />
      </div>
    </div>

    <v-loading
      class="my-5"
      v-if="loading"
      :center="true"
      :width="150"
    ></v-loading>

    <v-network-error
      v-else-if="networkError"
      @tryAgain="getInitialData"
      class="mt-4"
    ></v-network-error>

    <div class="table-responsive" v-else-if="orders.length">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Quantity</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order, index) in orders" :key="order.id">
            <th>{{ (index + 1) + (page * limit) - limit }}</th>
            <td class="text-nowrap">{{ order.shipment_details.full_name }}</td>
            <td>{{ order.email }}</td>
            <td>{{ order.quantity }}</td>
            <td class="text-nowrap">
              <v-status-badge :status="order.status" @click="() => { modals.status.active = true; modals.status.order = order }" />
            </td>
            <td class="text-right text-nowrap">
              <a href="#" class="btn btn-sm btn-light mr-1" @click.prevent="showInfoModal(order)">
                <i class="fa fa-eye mt-2"></i>
              </a>
              <a href="#" class="btn btn-sm btn-primary mr-1" @click.prevent="showStatusModal(order)">
                <i class="fa fa-edit mt-2"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <hr class="mt-0" />
      No orders found.
    </div>

    <v-paginator
      v-if="!loading && !networkError"
      :total="total"
      :perPage="limit"
      :centered="true"
      :currentPage="page"
      @pageChanged="onPageChanged"
    ></v-paginator>

    <v-info
      :active="modals.info.active"
      :order="modals.info.order"
      @close="() => { modals.status.isInfoActive = modals.info.active = false; }"
      @openStatusModal="() => { modals.info.active = !(modals.status.active = true); modals.status.isInfoActive = true; modals.status.order = modals.info.order; }"
    ></v-info>

    <v-status
      :active="modals.status.active"
      :order="modals.status.order"
      @close="() => { modals.status.active = false; modals.status.isInfoActive ? modals.info.active = true : null }"
      @updated="onStatusUpdated"
    ></v-status>

    <v-loading-modal
      :active="modals.loading.active"
    ></v-loading-modal>
  </div>
</template>

<script>
  import { getObjectWithoutEmptyValues } from '../../../utils/helpers.js'
  import VLoading from '../../../components/Loading.vue'
  import VNetworkError from '../../../components/NetworkError.vue'
  import VPaginator from '../../../components/Paginator.vue'
  import VStatusBadge from '../../../components/StatusBadge.vue'
  import VInput from '../../../components/form/Input.vue'
  import VSelect from '../../../components/form/Select.vue'
  import VExportToCsv from '../../../components/ExportToCSV.vue'
  import VLoadingModal from '../../../components/LoadingModal.vue'
  import VInfo from './Info.vue'
  import VStatus from './Status.vue'
  import * as orderService from '../../../services/orderService.js'
  import orderByMixin from '../../../mixins/orderByMixin.js'

  export default {
    name: 'Dashboard',
    mixins: [
      orderByMixin,
    ],
    components: {
      VPaginator,
      VLoading,
      VNetworkError,
      VStatusBadge,
      VInput,
      VSelect,
      VLoadingModal,
      VExportToCsv,
      VInfo,
      VStatus,
    },
    data() {
      return {
        loading: true,
        networkError: false,
        total: 0,
        page: 1,
        limit: 12,
        orders: [],
        modals: {
          info: {
            active: false,
            order: {
              status: '',
              shipment_details: {},
              sizes: []
            }
          },
          status: {
            isInfoActive: false,
            active: false,
            order: {
              status: '',
              shipment_details: {},
              sizes: []
            }
          },
          loading: {
            active: false
          }
        },
        filters: {
          search: '',
          status: null
        },
        orderBy: {
          field: 'createdAt',
          dir: 'desc'
        }
      }
    },
    computed: {
      statusOptions() {
        return [
          { id: 'PROCESSING', label: 'Processing' },
          { id: 'SHIPPING', label: 'Shipping' },
          { id: 'COMPLETE', label: 'Complete' },
        ]
      }
    },
    methods: {
      getInitialData() {
        const filters = this.getURLFilters(this.$route.query)
        this.loading = true
        this.networkError = false

        orderService.getAll({
          ...filters,
          orderField: this.orderBy.field,
          orderDir: this.orderBy.dir
        })
          .then(response => {
            this.handleOrdersResponse(response)
          }).catch(err => {
            this.handleResponseError(err)
          }).finally(() => {
            this.loading = false
          })
      },
      getOrders(query = this.$route.query) {
        const filters = this.getURLFilters(query)
        this.loading = true
        this.networkError = false

        orderService.getAll({
          ...filters,
          orderField: this.orderBy.field,
          orderDir: this.orderBy.dir
        })
          .then(response => {
            this.handleOrdersResponse(response)
          })
          .catch(err => {
            this.handleResponseError(err)
          })
          .finally(() => {
            this.loading = false
          })
      },
      handleOrdersResponse(response) {
        const { docs, totalDocs } = response.data
        this.total = totalDocs
        this.orders = docs
      },
      handleResponseError(err) {
        this.networkError = true
      },
      getURLFilters(query) {
        return {
          page: parseInt(query.page) || 1,
          search: query.search || '',
          status: query.status || null,
          limit: parseInt(query.limit) || 12
        }
      },
      setCurrentFilters(query) {
        const filters = this.getURLFilters(query)
        this.page = filters.page
        this.limit = filters.limit
        this.filters = filters
      },
      onPageChanged(pageNum) {
        this.onFiltered(null, pageNum)
      },
      onFiltered(_, page = null) {
        const query = getObjectWithoutEmptyValues({ ...this.filters, page })
        this.$router.push({ query }, () => {})
      },
      onSort() {
        this.getOrders()
      },
      onStatusUpdated(form) {
        this.modals.status.order.status = form.status
        this.modals.status.order.tracking = form.tracking
        this.getOrders()
      },
      showInfoModal(order) {
        this.modals.info.order = order
        this.showModal('info')
      },
      showStatusModal(order) {
        this.modals.status.order = order
        this.showModal('status')
      },
      showModal(modal) {
        this.modals[modal].active = true
      },
      hideModal(modal) {
        this.modals[modal].active = false
      }
    },
    mounted() {
      this.setCurrentFilters(this.$route.query)
      this.getInitialData()
    },
    beforeRouteUpdate(to, from, next) {
      this.setCurrentFilters(to.query)
      this.getOrders(to.query)
      next()
    }
  }
</script>
