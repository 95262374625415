<template>
  <v-modal :active="active" @close="$emit('close')" :large="true">
    <div slot="header">
      <h5 class="mb-0">
        View order #{{ order.id }}
      </h5>
    </div>
    <div slot="body">
      <v-status-badge :status="order.status" class="float-right mt-2" @click="$emit('openStatusModal')" />
      <p class="text-muted lead">Cart ({{ order.quantity }})</p>

      <div class="table-responsive">
        <table class="table table-striped">
          <tbody>
            <tr v-for="(quantity, size) in cartItems" :key="`cart-item-${size}`">
              <td>Bomber Jacker - {{ size }}</td>
              <td>{{ quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="text-muted lead">Shipping details</p>

      <div class="row">
        <div class="col-md-6 col-lg-4">
          <p><strong>Name: </strong>{{ order.shipment_details.full_name }}</p>
        </div>
        <div class="col-md-6 col-lg-4">
          <p><strong>Email: </strong>{{ order.email }}</p>
        </div>
        <div class="col-md-6 col-lg-4">
          <p><strong>Phone: </strong>{{ order.shipment_details.phone }}</p>
        </div>
        <div class="col-md-6 col-lg-4">
          <p><strong>Country: </strong>{{ order.shipment_details.country }}</p>
        </div>
        <div class="col-md-6 col-lg-4">
          <p><strong>State: </strong>{{ order.shipment_details.state }}</p>
        </div>
        <div class="col-md-6 col-lg-4">
          <p><strong>City: </strong>{{ order.shipment_details.city }}</p>
        </div>
        <div class="col-md-6 col-lg-4">
          <p><strong>Postal code: </strong>{{ order.shipment_details.postal_code }}</p>
        </div>
        <div class="col-md-6 col-lg-4">
          <p><strong>Address: </strong>{{ order.shipment_details.address }}</p>
        </div>
        <div class="col-md-6 col-lg-4">
          <p><strong>Additional info: </strong>{{ order.shipment_details.additional_info || 'N/A' }}</p>
        </div>
        <div class="offset-lg-8 col-md-6 col-lg-4">
          <p><strong class="text-brand pointer" @click="$emit('openStatusModal')">Tracking</strong>: {{ order.tracking || 'N/A' }}</p>
        </div>
      </div>

      <p class="text-muted lead">Others</p>

      <p><strong>Wallet address: </strong>{{ order.userAddress }}</p>
      <p>
        <strong>Requested at: </strong>
        <span v-tooltip="$options.filters.fromNow(order.createdAt)">
          {{ order.createdAt | prettyDatetime }}
        </span>
      </p>
      <p class="mb-0">
        <strong>Last update: </strong>
        <span v-if="order.updatedAt" v-tooltip="$options.filters.fromNow(order.updatedAt)">
          {{ order.updatedAt | prettyDatetime }}
        </span>
        <span v-else v-tooltip="$options.filters.fromNow(order.createdAt)">
          {{ order.createdAt | prettyDatetime }}
        </span>
      </p>
    </div>
    <div slot="footer">
      <div class="text-right">
        <button type="button" class="btn btn-light" @click="$emit('close')">
          Close
        </button>
      </div>
    </div>
  </v-modal>
</template>

<script>
  import VModal from '../../../components/Modal.vue'
  import VStatusBadge from '../../../components/StatusBadge.vue'

  export default {
    name: 'Info',
    components: {
      VModal,
      VStatusBadge,
    },
    props: {
      active: {
        type: Boolean,
        required: true,
      },
      order: {
        type: Object,
        required: true,
      }
    },
    computed: {
      cartItems() {
        const items = {}

        for (const size of this.order.sizes) {
          if (size in items) {
            items[size] += 1
          } else {
            items[size] = 1
          }
        }

        return items
      }
    }
  }
</script>
