<template>
  <div :class="getClass">
    <img src="/static/loader.svg" alt="Loading..." :width="width" />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 200,
    }
  },
  computed: {
    getClass() {
      if (this.fullscreen) {
        return 'fullscreen h-100 d-flex justify-content-center align-items-center'
      }

      return this.center ? 'text-center' : ''
    }
  }
}
</script>

<style scoped>
  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
    background-color: white;
  }
</style>
