<template>
  <div class="form-group">
    <label :for="id || name" v-if="label">
      {{ label  }}
      <small class="text-muted" v-if="hint">
        ({{ hint }})
      </small>
    </label>
    <div class="input-group">
      <div class="input-group-prepend" v-if="icon">
        <i :class="['input-icon align-self-center text-secondary mr-2', icon]"></i>
      </div>

      <input
        ref="input"
        :type="type || 'text'"
        :name="name"
        :id="id || name"
        :placeholder="placeholder"
        :class="{ 'form-control': true, 'is-invalid': error }"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :required="required"
        :disabled="disabled"
        :min="min"
        :max="max"
        @keydown.enter="$emit('enterPressed')"
      />

      <div class="input-group-append">
        <slot name="append" />
      </div>

      <span v-if="error" class="invalid-feedback" role="alert">
        <strong>{{ error }}</strong>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: [String, Number],
      required: false
    },
    error: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    min: {
      type: String|Number,
      required: false
    },
    max: {
      type: String|Number,
      required: false
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>
