<template>
  <div class="position-relative">
    <a href="javascript: void(0);" @click.stop="dropdown = true">
      <img src="/static/avatar.png" alt="Avatar" width="38" class="rounded-circle avatar">
    </a>
    <v-dropdown :active="dropdown" @close="dropdown = false">
      <router-link :to="{ name: 'Logout' }" @click.native="dropdown = false">
        Logout
      </router-link>
    </v-dropdown>
  </div>
</template>

<script>
import VDropdown from './Dropdown.vue'
import { mapState } from 'vuex'

export default {
  name: 'NavbarLinks',
  components: {
    VDropdown,
  },
  data() {
    return {
      dropdown: false
    }
  },
  computed: mapState(['currentUser'])
}
</script>

<style scoped>
  .avatar:hover {
    opacity: .7;
  }
</style>
