<template>
  <div class="text-center">
    <h2 class="display-1 font-weight-bold d-inline-block">
      {{ statusCode }}
    </h2>
    <p class="lead">
      {{ statusMessage || defaultMessage }}
    </p>
    <button v-if="!route" class="btn btn-primary" @click="$emit('tryAgain')">
      Try again
    </button>
    <router-link v-else :to="route" class="btn btn-primary">
      Back
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'RequestError',
  props: {
    statusCode: {
      type: Number,
      required: true
    },
    statusMessage: {
      type: String,
      required: false,
    },
    redirectTo: {
      type: String|Object,
      required: false,
    }
  },
  computed: {
    route() {
      const route = {
        name: this.redirectTo
      }

      return typeof this.redirectTo === 'string'
        ? route
        : this.redirectTo
    },
    defaultMessage() {
      const messages = {
        401: 'Unauthorized.',
        403: 'Forbidden.',
        404: 'Page not found.',
        500: 'Something went wrong. Please contact the webmaster.',
      }

      if (this.statusCode in messages) {
        return messages[this.statusCode]
      }

      return 'An unexpected error has ocurred. Please contact the webmaster.'
    }
  }
}
</script>
