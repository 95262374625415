import Vue from 'vue'
import VueRouter from 'vue-router'
import { setPageTitle } from '../utils/helpers'
import Auth from '../router/auth'
import Home from '../router/home'
import Common from '../router/common'
import scrollBehavior from './scrollBehavior'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes: [
    ...Auth,
    ...Home,
    ...Common,
  ]
})

router.beforeEach(async (to, from, next) => {
  setPageTitle(to.meta.title)
  next()
})

export default router
