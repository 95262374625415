<template>
  <v-confirmation-modal
    :active="active"
    header="Are you sure you want to leave?"
    body="You will be disconnected from your session."
    :submitting="submitting"
    submittingText="Disconnecting..."
    submitButtonText="Logout"
    submitButtonColor="primary"
    @close="onClose"
    @submit="submit"
  />
</template>

<script>
import VConfirmationModal from '../../components/ConfirmationModal.vue'
import { logout } from '../../services/authService.js'

export default {
  name: 'Logout',
  components: {
    VConfirmationModal,
  },
  data() {
    return {
      submitting: false,
      active: false,
    }
  },
  methods: {
    async submit() {
      this.submitting = true
      await logout()
      this.$router.replace('/login')
    },
    onClose() {
      this.active = false
      setTimeout(() => this.$router.push({
        name: 'Dashboard',
      }), 300)
    }
  },
  mounted() {
    setTimeout(() => this.active = true, 100)
  }
}
</script>
