<template>
  <div id="auth" class="d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-8 col-md-6 col-lg-4">
          <div class="text-center mb-4">
            <h2>Bomber Jacket <small class="d-block text-muted mt-2">Admin</small></h2>
          </div>

          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth',
};
</script>

<style scoped>
  #auth {
    height: 100%;
  }

  h2 small {
    font-size: 16px;
  }
</style>
