<template>
  <div id="dropdown" :class="{card: true, shadow: true, [`place-${place}`]: true, active}" @click.stop>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    place: {
      type: String,
      default: 'right'
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  watch: {
    active(isActive) {
      if (isActive) {
        window.addEventListener('click', this.close)
      } else {
        window.removeEventListener('click', this.close)
      }
    }
  }
};
</script>

<style scoped>
  #dropdown {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    background-color: white;
    width: 200px;
    margin-top: 20px;
    transition: visibility 200ms, opacity 200ms, margin-top 200ms;
    z-index: 3;
  }

  #dropdown.place-left {
    top: 0;
    left: 0;
  }

  #dropdown.place-right {
    top: 0;
    right: 0;
  }

  #dropdown.active {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }

  #dropdown a {
    padding: 12px;
    text-decoration: none;
    color: var(--dark-color);
    transition: background-color 100ms;
  }

  #dropdown a:hover {
    background-color: #f2f2f2;
  }

  #dropdown .divider {
    height: 1px;
    background-color: #f2f2f2;
  }
</style>
