<template>
  <button type="button" class="btn btn-success btn-sm shadow-sm" @click="handleClick">
    <i class="fa fa-fw fa-download"></i>
    Export To CSV
  </button>
</template>

<script>
import * as orderService from '../services/orderService.js'
import { ExportToCsv } from 'export-to-csv'

export default {
  name: 'ExportToCSV',
  methods: {
    handleClick() {
      const filters = {
        page: 1,
        status: 'PROCESSING',
        limit: 99999,
        orderField: 'createdAt',
        orderDir: 'desc',
      }

      this.$emit('beginExport')

      orderService.getAll(filters)
        .then(response => {
          const { docs } = response.data
          this.exportToCSV(docs)
        })
        .catch(err => {
          if (err.response && err.response.data.errors) {
            err.response.data.errors.forEach(err => {
              this.$showSnackbar(err.msg, 'danger')
            })
          }
        })
    },
    exportToCSV(orders) {
      const data = orders.map(order => ({
        'name': order.shipment_details.full_name,
        'email': order.email,
        'phone': order.shipment_details.phone,
        'street1': order.shipment_details.address,
        'street2': order.shipment_details.additional_info || '',
        'city': order.shipment_details.city,
        'state': order.shipment_details.state,
        'zip': order.shipment_details.postal_code,
        'country': order.shipment_details.country,
        'quantity': order.quantity,
        'sizes': order.sizes.join(',')
      }))

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)

      csvExporter.generateCsv(data)

      this.$emit('endExport')
      this.$showSnackbar('CSV generated successfully.')
    }
  }
}
</script>
