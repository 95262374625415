import isAuthenticated from '../middleware/isAuthenticated'
import Layout from '../../views/layouts/Home.vue'
import Dashboard from '../../views/home/dashboard/Index.vue'

export default [
  {
    path: '/home',
    component: Layout,
    beforeEnter: isAuthenticated,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard'
        }
      }
    ]
  }
]
