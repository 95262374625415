<template>
  <v-auth>
    <form @submit.prevent="submit">
      <v-input
        ref="email"
        type="text"
        name="email"
        placeholder="email"
        :error="errors.email"
        v-model="form.email"
        @input="clearInputError('email')"
        class="input-border"
        :disabled="submitting"
      />
      <v-input
        type="password"
        name="password"
        placeholder="password"
        :error="errors.password"
        v-model="form.password"
        @input="clearInputError('password')"
        class="input-border"
        :disabled="submitting"
      />
      <button type="submit" class="btn btn-primary btn-block" :disabled="submitting">
        {{ submitting ? 'Signing...' : 'Sign in' }}
      </button>
    </form>
  </v-auth>
</template>

<script>
import formMixin from '../../mixins/formMixin.js'
import VAuth from '../layouts/Auth.vue'
import VInput from '../../components/form/Input.vue'
import { login } from '../../services/authService.js'

export default {
  name: 'Login',
  mixins: [formMixin],
  components: {
    VAuth,
    VInput,
  },
  data() {
    return {
      submitting: false,
      form: {
        email: '',
        password: ''
      },
      errors: {}
    };
  },
  methods: {
    submit() {
      this.submitting = true

      login(this.form.email, this.form.password)
        .then(() => {
          const { redirect } = this.$route.query

          this.$router.replace(redirect || '/home')
        })
        .catch(err => {
          this.form.password = ''
          this.$refs.email.focus()

          const errorCodes = {
            'auth/invalid-email': 'The email must be in a valid format.',
            'auth/user-not-found': 'Email or password incorrect.',
            'auth/wrong-password': 'Email or password incorrect.',
            'auth/internal-error': 'The password and email must be set.',
            'auth/too-many-requests': 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
          }

          if (err.code in errorCodes) {
            this.errors.email = errorCodes[err.code]
          } else {
            this.$showSnackbar('Connection error. Please try again.', 'danger')
          }
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
};
</script>

<style scoped>
  button[type="submit"] {
    width: 160px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    text-transform: none!important;
    font-weight: 600;
    font-size: 18px;
    border-radius: 100px;
    background-color: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }

  button[type="submit"]:active {
    background-color: var(--primary-color)!important;
    border: 1px solid var(--primary-color)!important;
  }
</style>
