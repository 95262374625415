<template>
  <v-modal :active="active" :persist="true">
    <div slot="body">
      <div class="text-center">
        <img src="/static/loader.svg" alt="Loader" width="100">
      </div>
    </div>
  </v-modal>
</template>

<script>
import VModal from './Modal.vue'

export default {
  name: 'LoadingModal',
  components: {
    VModal,
  },
  props: {
    active: {
      type: Boolean,
      required: false
    }
  }
}
</script>
