import Login from '../../views/auth/Login.vue'
import Logout from '../../views/auth/Logout.vue'
import isGuest from '../middleware/isGuest'

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: isGuest,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      title: 'Logout'
    }
  }
]
