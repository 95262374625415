import axios from 'axios'
import config from '../config'
import { showSnackbar } from '../plugins/snackbar'

axios.defaults.baseURL = config.http.apiUrl
axios.defaults.headers.common['X-Timezone-Offset'] = new Date().getTimezoneOffset()

let latestNetworkErrorTime = null

axios.interceptors.response.use(response => response, err => {
  if (!latestNetworkErrorTime || (Date.now() - latestNetworkErrorTime) >= 1000) {
    if (!err.response) {
      showSnackbar('We were unable to complete your request. Please check your connection and try again.', 'danger')
    } else if (err.response.status >= 500) {
      showSnackbar('An unexpected error has ocurred.', 'danger')
    }

    latestNetworkErrorTime = Date.now()
  }

  return Promise.reject(err)
})

export function setAuthorization(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export function unsetAuthorization() {
  delete axios.defaults.headers.common['Authorization']
}

export default axios
