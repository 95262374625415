import config from '../config'

export function delayed(seconds) {
  return new Promise(resolve => {
    setTimeout(resolve, seconds)
  })
}

export function updateObj(oldObj, newObj) {
  if (oldObj) {
    for (let key in newObj) {
      if (key in oldObj) {
        oldObj[key] = newObj[key]
      }
    }
  }
}

export function strLimitMiddle(str, leftLength = 10, rightLength = 10) {
  if (str.length > leftLength + rightLength + 3) {
    const leftStr = str.trim().substring(0, leftLength).trim()
    const rightStr = str.trim().substring(str.length - rightLength).trim()

    return `${leftStr}...${rightStr}`
  }

  return str
}

export function secondsToMinutes(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = timeInSeconds - minutes * 60

  let secondsString = seconds

  if (seconds < 10) {
    secondsString = `0${secondsString}`
  }

  return `${minutes}:${secondsString}`
}

export function setPageTitle(title) {
  document.title = `DeadHeads Wearables Admin - ${title}`
}

export function uploadUrl(filename) {
  return config.http.apiUrl + '/uploads/' + filename
}

export function thumbnailUrl(filename) {
  return uploadUrl(filename).replace('.jpg', '_thumbnail.jpg')
}

export function randomStr(len = 20, charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789') {
  const arr = charset.split('')
  let result = '';

  for (let i = len; i > 0; i--) {
    result += arr[Math.floor(Math.random() * arr.length)];
  }

  return result;
}

export function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function join(items, word = 'and') {
  if (items.length > 1) {
    const last = items.pop()

    return `${items.join(', ')} ${word} ${last}`
  }

  return items[0]
}

export function orderByQuery(fields) {
  const orderBy = []
  for (const field in fields) {
    const dir = fields[field]
    if (dir !== null) {
      orderBy.push(`${field},${dir}`)
    }
  }

  return orderBy
}

export function caseInsensitive(str) {
  return str.toLowerCase()
    .replace(/(à|á|ä|ã|â)/g, 'a')
    .replace(/(è|é|ë|ê)/g, 'e')
    .replace(/(ì|í|ï|î)/g, 'i')
    .replace(/(ò|ó|ö|õ|ô)/g, 'o')
    .replace(/(ù|ú|ü|û)/g, 'u')
    .replace(/ñ/g, 'n')
    .replace(/ç/g, 'c')
}

export function isBoolean(value) {
  return value === true || value === false
}

export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export function getObjectWithoutEmptyValues(fields) {
  const query = {}

  for (const field in fields) {
    if (fields[field]) {
      query[field] = fields[field]
    }
  }

  return query
}

export function resetObj(fields) {
  Object.keys(fields).forEach(key => {
    const value = fields[key]

    if (Array.isArray(value)) {
      fields[key] = []
    } else if (isBoolean(value)) {
      fields[key] = false
    } else if (isObject(value)) {
      fields[key] = {}
    } else {
      fields[key] = null
    }
  })
}

export function currency(value) {
  if (value === null) return null
  const options = {
    style: 'currency',
    currency: 'BRL'
  }

  return Intl.NumberFormat('pt-BR', options).format(value)
}

export function fileSize(bytes) {
  if (bytes >= 1000000000) {
    return `${(bytes / 1e+9).toFixed(2)} GB`;
  } else if (bytes >= 1000000) {
    return `${(bytes / 1e+6).toFixed(2)} MB`;
  } else if (bytes >= 1000) {
    return `${(bytes / 1e+3).toFixed(2)} kB`;
  }

  return `${bytes} B`;
}

export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
