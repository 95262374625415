<template>
  <span :class="`pointer badge badge-${color}`" @click="$emit('click')">
    {{ status }}
  </span>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    color() {
      const colors = {
        'PROCESSING': 'info',
        'SHIPPING': 'warning',
        'COMPLETE': 'success'
      }

      return colors[this.status]
    }
  }
}
</script>
