import Vue from 'vue'
import VTooltip from 'v-tooltip'
import Vue2TouchEvents from 'vue2-touch-events'
import App from './App.vue'
import router from './router'
import store from './store'
import SnackbarPlugin from './plugins/snackbar'
import globalMixin from './mixins/globalMixin'
import './filters'
import './directives'

Vue.config.productionTip = false

Vue.use(SnackbarPlugin)
Vue.use(VTooltip)
Vue.use(Vue2TouchEvents)
Vue.mixin(globalMixin)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
