import Vue from 'vue'
import moment from 'moment'
import {
  strLimitMiddle,
  uploadUrl,
  thumbnailUrl,
  currency,
  fileSize,
  ucfirst,
} from '../utils/helpers'

Vue.filter('fileSize', function(bytes) {
  return fileSize(bytes)
})

Vue.filter('strLimitMiddle', function(str, leftLength, rightLength) {
  return strLimitMiddle(str, leftLength, rightLength)
})

Vue.filter('substring', function(str, start, end = null) {
  return str.substring(start, end || str.length)
})

Vue.filter('uploadUrl', function(filename) {
  return uploadUrl(filename)
})

Vue.filter('thumbnailUrl', function(filename) {
  return thumbnailUrl(filename)
})

Vue.filter('firstName', function(name) {
  if (!name) return null
  return name.split(' ')[0]
})

Vue.filter('datetime', function (value) {
  if (!value) return null
  return moment(value).format('MM/DD/YYYY HH:mm')
})

Vue.filter('fromNow', function (value) {
  if (!value) return null
  return moment(value).fromNow()
})

Vue.filter('utcdate', function (value) {
  if (!value) return null
  return moment(value).format('YYYY-MM-DD')
})

Vue.filter('prettyDatetime', function (value) {
  if (!value) return null
  return moment(value).format('ddd, MMMM DD, YYYY [at] h:mm a')
})

Vue.filter('currency', function (value) {
  return currency(value)
})

Vue.filter('ucfirst', function (value) {
  return ucfirst(value)
})
