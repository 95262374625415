<template>
  <v-modal :active="active" @close="$emit('close')" :large="true">
    <div slot="header">
      <h5 class="mb-0">
        Order #{{ order.id }}
      </h5>
    </div>
    <div slot="body">
      <form @submit.prevent="submit" :id="formId">
        <v-select
          name="status"
          :id="`${order.id}-status`"
          label="Status"
          :items="statusOptions"
          v-model="form.status"
          :disabled="!canSubmit"
          :hasDefault="false"
        />
        <v-input
          type="text"
          :id="`${order.id}-tracking`"
          name="tracking"
          label="Tracking"
          v-model="form.tracking"
          :disabled="!canSubmit"
        />
      </form>
    </div>
    <div slot="footer">
      <div class="d-flex justify-content-between align-items-center">
        <div class="text-right">
          <button type="button" class="btn btn-light" @click="$emit('close')" v-if="!submitting">
            Cancel
          </button>
          <button type="submit" class="btn btn-primary ml-1" :form="formId" :disabled="!canSubmit">
            {{ submitting ? 'Updating...' : 'Update' }}
          </button>
        </div>
      </div>
    </div>
  </v-modal>
</template>

<script>
  import VModal from '../../../components/Modal.vue'
  import VSelect from '../../../components/form/Select.vue'
  import VInput from '../../../components/form/Input.vue'
  import { updateOrder } from '../../../services/orderService.js'

  export default {
    name: 'UserEditModal',
    components: {
      VModal,
      VSelect,
      VInput,
    },
    props: {
      active: {
        type: Boolean,
        required: true,
      },
      order: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        submitting: false,
        form: {
          status: null,
          tracking: null
        }
      }
    },
    computed: {
      formId() {
        return 'order-status'
      },
      canSubmit() {
        return !this.submitting
      },
      statusOptions() {
        return [
          { id: 'PROCESSING', label: 'Processing' },
          { id: 'SHIPPING', label: 'Shipping' },
          { id: 'COMPLETE', label: 'Complete' },
        ]
      }
    },
    watch: {
      order(v) {
        this.form.status = v.status
        this.form.tracking = v.tracking
      }
    },
    methods: {
      submit() {
        this.submitting = true

        updateOrder(this.order.id, this.form)
          .then(response => {
            this.$showSnackbar('Order updated.')
            this.$emit('updated', this.form)
            this.$emit('close')
          })
          .catch(err => {
            if (err.response) {
              this.$showSnackbar(err.response.data.message, 'danger')
            }
          })
          .finally(() => {
            this.submitting = false
          })
      }
    }
  }
</script>
