<template>
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-primary">
    <!-- <Search /> -->
    <i class="fa fa-bars pointer fs-3 text-white" @click="toggleSidebar" @click.stop></i>

    <div class="navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <v-navbar-links />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import VNavbarLinks from './NavbarLinks.vue'

export default {
  name: 'Navbar',
  components: {
    VNavbarLinks,
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar')
    }
  }
}
</script>

<style scoped>
  .navbar-nav li:not(:last-child) {
    margin-right: 24px;
  }

  .divider {
    height: 38px;
    width: 1px;
    background-color: rgba(255, 255, 255, .3);
  }

  .fa.fa-bars:hover {
    opacity: .7;
  }

  @media (max-width: 991px) {
    .navbar-collapse {
      -ms-flex-preferred-size: auto;
      flex-basis: auto !important;
      display: flex !important;
    }

    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }

  @media (max-width: 767px) {
    .navbar {
      background-color: var(--primary-color) !important;
    }

    .fa.fa-bars {
      color: white;
    }

    li.divider {
      background-color: var(--primary-hover) !important;
    }
  }
</style>
