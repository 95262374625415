export default {
  computed: {
    orderByIcon() {
      return this.orderBy.dir == 'asc'
        ? 'fa-sort-up'
        : 'fa-sort-down'
    }
  },
  methods: {
    sort(col) {
      const isSame = col == this.orderBy.field
      const isDesc = this.orderBy.dir == 'desc'

      if (isSame && isDesc) {
        this.orderBy.field = null
        this.orderBy.dir = null
      } else if (isSame) {
        this.orderBy.dir = 'desc'
      } else {
        this.orderBy.field = col
        this.orderBy.dir = 'asc'
      }

      this.onSort()
    }
  }
}
