<template>
  <div id="sidebar" class="d-print-none" @click.stop>
    <div class="overlay" @click="$emit('close-drawer')"></div>
    <div class="sidebar-content">
      <div class="p-3 text-center">
        <router-link :to="{ name: 'Dashboard' }" @click.native="$emit('close-drawer')" class="text-decoration-none">
          <img src="/static/logo.jpg" alt="Logo" class="img-fluid" width="90%">
        </router-link>
      </div>
      <ul class="list-unstyled links">
        <li>
          <router-link :to="{ name: 'Dashboard' }" @click.native="$emit('close-drawer')">
            <i class="fa fa-fw fa-tachometer-alt"></i>
            <span class="link-text">
              Dashboard
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Logout' }" @click.native="$emit('close-drawer')">
            <i class="fa fa-fw fa-sign-out-alt"></i>
            <span class="link-text">
              Logout
            </span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Sidebar',
    props: {
      active: {
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style scoped>
  #sidebar {
    z-index: 4;
  }

  #sidebar.active {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  #sidebar.active .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .175);
    z-index: 2;
    transition: background-color 150ms ease-out;
  }

  .sidebar-content {
    background-color: var(--secondary-color);
    height: 100%;
    width: 260px;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .links a,
  .links .heading {
    color: var(--primary-color);
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 12px 24px;
  }

  .links .heading {
    font-size: 12px;
    color: rgba(0, 0, 0, .5);
    letter-spacing: 1.5px;
  }

  .links a:hover {
    background-color: rgba(0, 0, 0, .1);
  }

  .links a.router-link-active {
    background-color: var(--primary-color);
    color: white;
  }

  .links .divider {
    margin-top: 8px;
    margin-bottom: 8px;
    border-top: 1px solid rgba(0, 0, 0, .1)
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #52515a;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9a9595;
    border-left: 1px solid #52515a;
    border-right: 1px solid #52515a;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #828187;
  }

  /* Handle on active */
  ::-webkit-scrollbar-thumb:active {
    background: #606066;
  }

  @media (max-width: 767px) {
    .sidebar-content {
      position: fixed;
      z-index: 4;
      height: 100%;
      /* box-shadow: 0 1rem 3rem rgba(0,0,0,.175); */
      left: -260px;
      transition: left 150ms ease-out;
    }

    #sidebar.active .sidebar-content {
      left: 0;
      width: 260px;
    }
  }

  @media (min-width: 768px) {
    .overlay {
      display: none;
    }

    #sidebar.active {
      pointer-events: none;
    }

    #sidebar.active .sidebar-content {
      pointer-events: all;
      width: 115px;
    }

    #sidebar.active .sidebar-content .link-text,
    #sidebar.active .links .heading {
      font-size: 11px;
      letter-spacing: 1.0px;
      display: block;
    }

    #sidebar.active .links a,
    #sidebar.active .links .heading {
      padding: 6px 4px;
      text-align: center;
    }
  }
</style>
