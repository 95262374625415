export default {
  methods: {
    clearInputError(name) {
      this.errors[name] = "";
    },
    prefixed(field) {
      if (this.prefix) {
        return `${this.prefix}-${field}`
      }

      return field
    }
  }
}
