import Error404 from '../views/Error404.vue'

export default [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '**',
    name: 'Error404',
    component: Error404,
    meta: {
      title: 'Page not found'
    }
  }
]
