import Vue from 'vue'

Vue.directive('disabled-if', {
  inserted: function (el, { value }) {
    if (value === true) {
      el.classList.add('disabled')
      el.disabled = true
    }
  },
  unbind: function (el) {
    el.classList.remove('disabled')
    el.disabled = false
  },
  update: function (el, { value }) {
    if (value === true) {
      el.classList.add('disabled')
      el.disabled = true
    } else {
      el.classList.remove('disabled')
      el.disabled = false
    }
  }
})
