<template>
  <div id="app">
    <transition name="slide-fade" :duration="200" mode="out-in">
      <router-view />
    </transition>
    <v-snackbar-container />
  </div>
</template>

<script>
import VSnackbarContainer from "./components/SnackbarContainer.vue";

export default {
  name: 'App',
  components: {
    VSnackbarContainer,
  }
}
</script>
