import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbars: [],
  },
  mutations: {
    PUSH_SNACKBAR(state, snackbar) {
      state.snackbars.push(snackbar)
    },
    POP_SNACKBAR(state, snackbar) {
      state.snackbars = state.snackbars.filter(sb => sb.data.id != snackbar.data.id)
    }
  }
})
