<template>
  <div id="home" class="d-flex" :class="sidebarActiveClass" v-touch:start="setSwipeStartPX" v-touch:swipe.left="handleSwipeLeft" v-touch:swipe.right="handleSwipeRight">
    <v-sidebar ref="sidebar" :class="sidebarActiveClass" @close-drawer="closeDrawerOnMobile" :active="sidebarActive" />
    <div class="content-wrapper w-100">
      <v-navbar @toggle-sidebar="onSidebarToggle" />
      <div class="p-4">
        <transition name="slide-fade" :duration="200" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import VSidebar from '../../components/Sidebar.vue'
import VNavbar from '../../components/Navbar.vue'

export default {
  name: 'Home',
  components: {
    VSidebar,
    VNavbar,
  },
  data() {
    return {
      sidebarActive: false,
      swipeStartPX: 0,
    }
  },
  computed: {
   sidebarActiveClass() {
     return this.sidebarActive ? 'active' : ''
   }
  },
  watch: {
    sidebarActive(isActive) {
      if (window.innerWidth < 768) {
        if (isActive) {
          this.$refs.sidebar.$el.querySelector('.sidebar-content').scrollTo(0, 0)
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      }
    }
  },
  methods: {
    closeDrawer() {
      this.sidebarActive = false;
    },
    closeDrawerOnMobile() {
      window.localStorage.setItem('sidebar', false)
      if (window.innerWidth < 768) {
        this.closeDrawer()
      }
    },
    onSidebarToggle() {
      this.sidebarActive = !this.sidebarActive
      window.localStorage.setItem('sidebar', this.sidebarActive)
    },
    handleSwipeLeft() {
      if (window.innerWidth < 768) {
        this.sidebarActive = false;
      }
    },
    handleSwipeRight() {
      if (window.innerWidth < 768 && this.swipeStartPX <= 30) {
        this.sidebarActive = true;
      }
    },
    setSwipeStartPX(e) {
      if (e.constructor.name == 'MouseEvent') {
        this.swipeStartPX = e.pageX
      } else {
        this.swipeStartPX = e.changedTouches[0].pageX
      }
    }
  },
  mounted() {
    if (window.innerWidth >= 768) {
      this.sidebarActive = window.localStorage.getItem('sidebar') == 'true'
    }
  }
};
</script>

<style>
  #home {
    min-height: 100%;
  }

  #home .content-wrapper {
    padding-left: 260px;
    transition: padding-left 150ms ease-out;
  }

  #home.active .content-wrapper {
    padding-left: 115px;
  }

  @media (max-width: 767px) {
    #home .content-wrapper,
    #home.active .content-wrapper {
      padding-left: 0px;
    }
  }

  @media print {
    #home .content-wrapper {
      padding-left: 0px;
    }
  }
</style>
