<template>
  <div class="form-group">
    <label :for="id || name" v-if="label">
      {{ label  }}
      <small class="text-muted" v-if="hint">
        ({{ hint }})
      </small>
    </label>
    <div class="input-group">
      <div class="input-group-prepend" v-if="icon">
        <i :class="['input-icon align-self-center text-secondary mr-2', icon]"></i>
      </div>

      <input
        ref="input"
        type="text"
        :name="name"
        :id="id || name"
        :placeholder="placeholder"
        :class="{ 'form-control': true, 'is-invalid': error }"
        :disabled="disabled"
        @focus="onInputFocus"
        @blur="onInputBlur"
        v-model="search"
        @input="showList = true"
        @keydown.enter="selectFirst"
        @keydown.tab="onTabPressed"
        @keydown.esc="onEscPressed"
        autocomplete="off"
        :readonly="!searchable && !disabled"
        @mousedown="onInputMousedown"
      />

      <div class="input-group-append">
        <slot name="append" />
      </div>

      <span v-if="error" class="invalid-feedback" role="alert">
        <strong>{{ error }}</strong>
      </span>

      <div
        class="options shadow w-100 position-absolute"
        style="z-index: 3; max-height: 300px; overflow-y: auto; margin-top: 40px;"
        v-if="showList"
        @mouseenter="hoveringList = true"
        @mouseleave="hoveringList = false"
      >
        <div
          v-if="hasDefault"
          :class="`pointer ${value == null ? 'selected' : ''}`"
          @click="unselect"
        >
          {{ defaultOption }}
        </div>
        <div
          v-for="(item, index) in filteredItems"
          :class="`pointer ${item.id == value ? 'selected' : ''}`"
          @click="selectItem(item)"
          :key="getOptionKey(index, item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { caseInsensitive } from '../..//utils/helpers.js'

export default {
  name: 'Select',
  props: {
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    defaultOption: {
      type: String,
      required: false,
      default: 'Select one'
    },
    hint: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: String|Number,
      required: false
    },
    error: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    searchable: {
      type: Boolean,
      required: false
    },
    hasDefault: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      search: '',
      showList: false,
      hoveringList: false,
      filteredItems: [],
    }
  },
  watch: {
    search(v) {
      this.filteredItems = [...this.items].filter(item => caseInsensitive(item.label)
        .includes(caseInsensitive(v)))
    },
    value(v) {
      if (v === null) {
        this.unselect()
      } else {
        const item = this.items.find(item => item.id == v)

        if (item) {
          this.search = item.label
        }
      }
    }
  },
  methods: {
    onInputFocus() {
      this.filteredItems = [...this.items]
      this.showList = true

      if (this.search == this.defaultOption && this.searchable) {
        this.search = ''
      }
    },
    onInputBlur() {
      if (!this.hoveringList) {
        this.setCurrentInputValue()
      }
    },
    onTabPressed() {
      this.showList = false
      this.setCurrentInputValue()
    },
    onEscPressed() {
      this.showList = false
      this.$refs.input.blur()
      this.setCurrentInputValue()
    },
    onInputMousedown() {
      if (!this.searchable) {
        this.showList = !this.showList
      }
    },
    setCurrentInputValue() {
      this.showList = false

      if (this.search != this.defaultOption && !this.items.find(item => item.label == this.search)) {
        if (this.value) {
          this.search = this.items.find(item => item.id == this.value).label
        } else {
          this.search = this.defaultOption
        }
      }
    },
    selectItem(item) {
      this.hoveringList = false
      this.showList = false

      if (this.search != item.label) {
        this.$emit('input', item.id)
      }

      this.search = item.label
      this.$refs.input.blur()
    },
    selectFirst() {
      if (this.filteredItems.length > 0 && this.search.length > 0) {
        this.selectItem(this.filteredItems[0])
      } else {
        this.hoveringList = false
        this.showList = false
        this.$emit('input', null)
        this.$refs.input.blur()
        this.search = this.defaultOption
      }
    },
    unselect() {
      this.hoveringList = false
      this.showList = false
      this.search = this.defaultOption

      if (this.value != null) {
        this.$emit('input', null)
      }
    },
    getOptionKey(index, item) {
      if (item.id == null || typeof item.id == 'boolean') {
        return index
      }

      return item.id
    },
    setInitialSearchText() {
      if (this.value && this.items.length > 0) {
        this.search = this.items.find(item => item.id == this.value).label
      } else {
        this.search = this.defaultOption
      }
    }
  },
  mounted() {
    if (this.items.length > 0) {
      this.$nextTick(() => {
        this.setInitialSearchText()
      })
    } else {
      this.$watch('items', v => {
        this.setInitialSearchText()
      })
    }
  }
}
</script>

<style scoped>
  input:read-only:not(.is-invalid) {
    background-color: var(--secondary-color);
    cursor: pointer;
  }

  input:read-only:focus:not(.is-invalid) {
    background-color: var(--secondary-hover);
  }

  .options {
    background-color: white;
  }

  .options > div {
    padding: 6px 12px;
  }

  .options > div:hover {
    background-color: #f2f2f2;
  }

  .options > div.selected {
    background-color: var(--primary-color);
    color: white;
  }
</style>
