import { getCurrentUser } from "../../services/authService"

export default async function (to, from, next) {
  const currentUser = await getCurrentUser()

  if (currentUser) {
    next('/home')
  } else {
    next()
  }
}
