<template>
  <div class="d-flex align-items-center justify-content-center h-100">
    <v-request-error
      :statusCode="404"
      :redirectTo="'Dashboard'" />
  </div>
</template>

<script>
import VRequestError from '../components/RequestError.vue'

export default {
  name: 'Error404',
  components: {
    VRequestError,
  }
};
</script>
